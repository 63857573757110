import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Paper,
  Grid,
  Typography,
  Box,
  Card,
  CardContent,
  Button,
  CircularProgress,
  Divider,
  Chip,
} from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';


const ViewOrganization = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');
  
  const [organization, setOrganization] = useState(null);
  const [loading, setLoading] = useState(true);
  const colors = ['#E57373', '#81C784', '#64B5F6', '#FFD54F', '#BA68C8', '#4DB6AC'];


  useEffect(() => {
    if (!id) {
      navigate('/organizations');
      return;
    }
    fetchOrganizationDetails();
  }, [id]);

  const fetchOrganizationDetails = async () => {
    const bearerToken = localStorage.getItem('ASIToken');
    try {
      const response = await axios.get(`/api/v1/superadmin/organizations/${id}`, {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
        },
      });
      setOrganization(response.data);
      setLoading(false);
    } catch (error) {
      toast.error(error.response?.data?.message || 'Failed to fetch organization details');
      navigate('/organizations');
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (!organization) {
    return null;
  }

  return (
    <Box sx={{ p: 3 }}>
       

      <Paper sx={{ p: 3 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Box>
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate(`/edit-organization?id=${id}`)}
              sx={{ mr: 1 }}
            >
              Edit
            </Button>
            <Button
              variant="outlined"
              onClick={() => navigate('/organizations')}
            >
              Back
            </Button>
          </Box>
        </Box>
        <Divider sx={{ my: 2 }} />

        <Grid container spacing={3}>

          {/* Basic Information */}
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography color="textSecondary"><strong>Organization Name</strong></Typography>
                    <Typography variant="body1" gutterBottom>{organization.name}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography color="textSecondary"><strong>Client ID</strong></Typography>
                    <Typography variant="body1" gutterBottom>{organization.clientId}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography color="textSecondary"><strong>Created On</strong></Typography>
                    <Typography variant="body1" gutterBottom>
                      {new Date(organization.createdAt).toLocaleDateString()}
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography color="textSecondary"><strong>Primary User</strong></Typography>
                    <Typography variant="body1" gutterBottom>{organization.primaryUser.firstName} {organization.primaryUser.lastName}</Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography color="textSecondary"><strong>Primary User Email</strong></Typography>
                    <Typography variant="body1" gutterBottom>{organization.primaryUser.email}
                       </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography color="textSecondary"><strong>Primary User Phone</strong></Typography>
                    <Typography variant="body1" gutterBottom>{organization.phone}
                       </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography color="textSecondary"><strong>Address</strong></Typography>
                    <Typography variant="body1" gutterBottom>{organization.address}
                       </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography color="textSecondary"><strong>Country</strong></Typography>
                    <Typography variant="body1" gutterBottom>{organization.country}
                       </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography color="textSecondary"><strong>Tax ID</strong></Typography>
                    <Typography variant="body1" gutterBottom>{organization.taxId}
                       </Typography>
                  </Grid>
                  <Grid item xs={12}>
  <Typography color="textSecondary"><strong>Modules Enabled</strong></Typography>
  <Typography variant="body1" gutterBottom>
    {organization.modules.map((module) => module.toUpperCase()).join(', ')}
  </Typography>
</Grid>

                </Grid>
              </CardContent>
            </Card>
          </Grid>
         

         
          
        </Grid>
      </Paper>
    </Box>
  );
};

export default ViewOrganization;