import React from "react";

import {
 
  CContainer,
} from '@coreui/react'


import './style.css'; 

import ApplicationFindingsByType from "./dashboardComponents/applicationFindingsByType";
import Counters from "./dashboardComponents/counters";
import Applications from "./dashboardComponents/applications";
import TopOpenApplicationFindings from "./dashboardComponents/topOpenApplicationFindings";
import Top10VulnerableApplications from "./dashboardComponents/top10VulnerableApplications";
import UniqueFindingDistributionBySeverity from "./dashboardComponents/uniqueFindingDistributionBySeverity";
import CWETop25MostDangerous from "./dashboardComponents/cweTop25MostDangerous";
import OWASPDistribution from "./dashboardComponents/owaspDistribution";
import ApplicationFindingsDiscoveredVsResolved from "./dashboardComponents/applicationFindingsDiscoveredVsResolved";
import Top10UniqueApplicationFindings from "./dashboardComponents/top10UniqueApplicationFindings";
import VulnerabilityTrends from "./dashboardComponents/vulnerabilityTrends";


const UserDashboard = () => {
  
  return (
    <>

      <CContainer style={{width:'100%'}}>

        <div style={{ display: 'flex',
           flexDirection: 'row', 
           justifyContent: 'space-between', width: '100%', flexWrap: 'wrap' }}>

          <ApplicationFindingsByType />
          <Counters />
          <Applications />
          
          <TopOpenApplicationFindings />          
          <Top10VulnerableApplications />
          <UniqueFindingDistributionBySeverity />
          
          <CWETop25MostDangerous />
          <OWASPDistribution />
          <ApplicationFindingsDiscoveredVsResolved />
          <Top10UniqueApplicationFindings />
          <VulnerabilityTrends />

        </div>


      </CContainer>
    </>
  )
}

export default UserDashboard