import React, { useState, useEffect, useRef } from "react";
import { CFormInput, CButton, CFormSelect, CTable, CToast, CToastBody, CToaster } from '@coreui/react'
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useLocation } from 'react-router-dom'
import { useParams, useNavigate } from 'react-router-dom'

import axios from 'axios';

import { CSSProperties } from "react";
import GridLoader from "react-spinners/GridLoader";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import Modal from 'react-modal';
import { AiFillCloseCircle } from "react-icons/ai";
import zIndex from "@mui/material/styles/zIndex";
import { borderColor } from "@mui/system";
import { CircularProgress, Menu, MenuItem } from '@mui/material';

import { IoMdArrowRoundBack } from "react-icons/io";
import { BiExport } from "react-icons/bi";
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";
import { CgNotes } from "react-icons/cg";
import { FaCaretDown } from "react-icons/fa";
import CustomReportModal from './CustomReportModal';

import Chart from 'react-apexcharts'

const ScanDetails = () => {

  const location = useLocation();

  const [searchText, setSearchText] = useState('');
  const [filterList, setFilterList] = useState({});
  const [filterOptions, setFilterOptions] = useState({
    severities: [],
    titles: [],
    filePaths: [],
  });
  const [severityCounts, setSeverityCounts] = useState([0,0,0,0,0]);
  
  const [searchInput, setSearchInput] = useState('');
  const prevSearchInput = useRef(searchInput);

  const [toast, addToast] = useState(0)
  const navigate = useNavigate()
  const [scanId, setScanId] = useState('')
  const [userId, setUserId] = useState('')
  const [sastScan, setSastScan] = useState(null)
  const [onLoading, setOnLoading] = useState(true)
  const [modalIsOpen, setModalIsOpen] = React.useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [customReportModalOpen, setCustomReportModalOpen] = useState(false)
  const [currentVulnerability, setCurrentVulnerability] = React.useState(null)

  const [page, setPage] = useState(0)
  const [count, setCount] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const [exportingPDF, setExportingPDF] = useState(false)

  const toaster = useRef()
  const exampleToast = (
    <CToast>
      <CToastBody>Success</CToastBody>
    </CToast>
  )

  const customStyles = {
    content: {
      top: '20%',
      left: '20%',
      width: '60%',
      right: 'auto',
      bottom: 'auto',
      height: '70%',
      backgroundColor: '#E1E1E1',
      borderRadius: 15,
      borderColor: 'yellow',
      zIndex: 1000,
    },
  }

  const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  }

  ChartJS.register(ArcElement, Tooltip, Legend)

  useEffect(() => {
    window.scrollTo(0, 0)
    setOnLoading(true)

    var arr = location.search.split('=')
    var theScanId = arr[1]
    setScanId(theScanId)

    loadScanDetails(theScanId, 0, rowsPerPage, '', {})

    var user = JSON.parse(localStorage.getItem('ASIUser'))
    setUserId(user._id)
  }, [])

  useEffect(() => {
    if (prevSearchInput.current && searchInput.length === 0) {
      loadScanDetails(
        scanId,
        0,
        rowsPerPage,
        '',
        filterList
      );
    }
    prevSearchInput.current = searchInput;
  }, [searchInput]);

  const handleGenerateCustomReport = (selectedOptions) => {
    const optionsString = JSON.stringify(selectedOptions)
    const urlToOpen = global.reportAPIURL + '/api/v1/download/sast-report-download/' + scanId + '/' + userId + '/custom/' + optionsString
    window.open(urlToOpen, '_blank')
  }

  const loadScanDetails = async (theScanId, page, rowsPerPage, searchText, filterList) => {
    setOnLoading(true)
    try {
      const queryParams = new URLSearchParams();
      
      if (searchText) {
        queryParams.append('search', searchText);
      }
      
      if (Object.keys(filterList).length > 0) {
        queryParams.append('filterList', JSON.stringify(filterList));
      }
      
      const queryString = queryParams.toString();
      const url = `/api/v1/sast-scans/details/${theScanId}/${page}/${rowsPerPage}${queryString ? `?${queryString}` : ''}`;

      const token = localStorage.getItem('ASIToken');
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setSastScan(response.data.sastScan);
      setCount(response.data.totalCount);
      setFilterOptions(response.data.filterOptions);
      setSeverityCounts(response.data.severityCounts);
    } catch (error) {
      console.error('Error loading scan details:', error);
    } finally {
      setOnLoading(false);
    }
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleMenuItemClick = (action) => {
    setExportingPDF(true)
    switch (action) {
      case 'summary':
        downloadSummaryReport()
        break
      case 'detailed':
        downloadDetailedReport()
        break
      case 'custom':
        downloadCustomReport()
        break
      case 'xml':
        exportToXML()
        break
      case 'json':
        exportToJSON()
        break
      default:
        break
    }
    setExportingPDF(false)
    handleClose()
  }

  const downloadSummaryReport = () => {
    const urlToOpen = global.reportAPIURL + '/api/v1/download/sast-report-download/' + scanId + '/' + userId + '/summary/none'
    window.open(urlToOpen, '_blank')
  }

  const downloadDetailedReport = () => {
    downloadPDF()
  }

  const downloadCustomReport = () => {
    setCustomReportModalOpen(true)
  }

  const downloadPDF = async () => {
    const urlToOpen = global.reportAPIURL + '/api/v1/download/sast-report-download/' + scanId + '/' + userId + '/full/none'
    window.open(urlToOpen, '_blank')
  }

  const exportToXML = () => {
    const jsonToXml = (obj, rootName) => {
      let xml = ''
      if (rootName) {
        xml += `<${rootName}>`
      }

      for (const key in obj) {
        if (Array.isArray(obj[key])) {
          xml += `<${key}>`
          for (const item of obj[key]) {
            if (typeof item === 'object') {
              xml += jsonToXml(item, 'item')
            } else {
              xml += `<item>${item}</item>`
            }
          }
          xml += `</${key}>`
        } else if (typeof obj[key] === 'object' && obj[key] !== null) {
          xml += jsonToXml(obj[key], key)
        } else {
          xml += `<${key}>${obj[key]}</${key}>`
        }
      }

      if (rootName) {
        xml += `</${rootName}>`
      }
      return xml
    }

    const formatXml = (xml) => {
      const PADDING = ' '.repeat(2)
      const reg = /(>)(<)(\/*)/g
      let pad = 0
      xml = xml.replace(reg, '$1\r\n$2$3')
      return xml.split('\r\n').map((node) => {
        let indent = 0
        if (node.match(/.+<\/\w[^>]*>$/)) {
          indent = 0
        } else if (node.match(/^<\/\w/)) {
          if (pad !== 0) {
            pad -= 1
          }
        } else if (node.match(/^<\w([^>]*[^\/])?>.*$/)) {
          indent = 1
        } else {
          indent = 0
        }

        const padding = PADDING.repeat(pad)
        pad += indent
        return padding + node
      }).join('\r\n')
    }

    const xmlString = jsonToXml(sastScan, 'SASTScan')
    const formattedXml = formatXml(xmlString)
    const blob = new Blob([formattedXml], { type: 'application/xml' })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.download = 'SAST_Scan_Report_' + sastScan._id + '.xml'
    link.click()
    URL.revokeObjectURL(url)
  }

  const exportToJSON = () => {
    const jsonString = JSON.stringify(sastScan, null, 2)
    const blob = new Blob([jsonString], { type: 'application/json' })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.download = 'SAST_Scan_Report_' + sastScan._id + '.json'
    link.click()
    URL.revokeObjectURL(url)
  }

  const openRemediationModal = async (value) => {
    setCurrentVulnerability(value)
    setModalIsOpen(true)
  }

  const closeModal = async () => {
    setModalIsOpen(false)
  }

  const goBack = async () => {
    navigate('/sast-scans')
  }

  const columns = [
    {
      label: "#",
      options: {
        filter: false,
        download: true,
      }
    },
    {
      label: "Title",
      options: {
        filter: true,
        download: true,
        filterOptions: {
          names: filterOptions.titles,
          logic: (value, filters) => {
            if (filters.length === 0) return false;
            return !filters.includes(value);
          }
        },
        filterList: filterList['Title'] || []
      }
    },
    {
      label: "Description",
      options: {
        filter: false,
        download: true,
      }
    },
    {
      label: "File Path",
      options: {
        filter: true,
        download: true,
        filterOptions: {
          names: filterOptions.filePaths,
          logic: (value, filters) => {
            if (filters.length === 0) return false;
            return !filters.includes(value);
          }
        },
        filterList: filterList['File Path'] || []
      }
    },
    {
      label: "Start",
      options: {
        filter: false,
        download: true,
      }
    },
    {
      label: "End",
      options: {
        filter: false,
        download: true,
      }
    },
    {
      label: "Severity",
      options: {
        filter: true,
        download: true,
        filterOptions: {
          names: filterOptions.severities,
          logic: (value, filters) => {
            if (filters.length === 0) return false;
            return !filters.includes(value);
          }
        },
        filterList: filterList['Severity'] || [],
        customBodyRender: (value, tableMeta, updateValue) => {
          let bgColor
          let theColor

          if (value === 'CRITICAL') {
            bgColor = '#990000'
            theColor = '#fff'
          } else if (value === 'HIGH') {
            bgColor = '#FF6600'
            theColor = '#fff'
          } else if (value === 'MEDIUM') {
            bgColor = '#FFCC00'
            theColor = 'black'
          } else if (value === 'LOW') {
            bgColor = '#669933'
            theColor = '#000'
          } else if (value === 'INFORMATIONAL') {
            bgColor = '#0094D4'
            theColor = '#000'
          }

          return (
            <div style={{
              display: "flex",
              alignItems: "center"
            }}>
              <text style={{
                padding: 5,
                backgroundColor: bgColor,
                color: theColor,
                width: 120,
                textAlign: 'center',
                borderRadius: 10,
                fontSize: 13
              }}>{value}</text>
            </div>
          )
        }
      }
    },
    {
      label: "OWASP",
      options: {
        filter: false,
        download: true,
        display: sastScan && sastScan.application.owaspTop10Enabled ? true : false,
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value !== '---') {
            return (
              <div style={{
                display: "flex",
                alignItems: "center",
                flexDirection: 'column',
              }}>
                {value.map(val => (
                  <text style={{
                    padding: 5,
                    margin: 5,
                    backgroundColor: '#fce4ec',
                    color: '#000',
                    fontWeight: 'bold',
                    width: 120,
                    textAlign: 'center',
                    borderRadius: 10,
                    fontSize: 13
                  }}>{val}</text>
                ))}
              </div>
            )
          } else {
            return (
              <div style={{
                display: "flex",
                alignItems: "center"
              }}>
                ---
              </div>
            )
          }
        }
      }
    },
    {
      label: "CWE",
      options: {
        filter: false,
        download: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value !== '---') {
            return (
              <div style={{
                display: "flex",
                alignItems: "center",
                flexDirection: 'column',
              }}>
                {value.map(val => (
                  <text style={{
                    padding: 5,
                    margin: 5,
                    backgroundColor: '#e8f5e9',
                    color: '#000',
                    fontWeight: 'bold',
                    width: 120,
                    textAlign: 'center',
                    borderRadius: 10,
                    fontSize: 13
                  }}>{val}</text>
                ))}
              </div>
            )
          } else {
            return (
              <div style={{
                display: "flex",
                alignItems: "center"
              }}>
                ---
              </div>
            )
          }
        }
      }
    },
    {
      label: "PCI DSS Violations",
      options: {
        filter: false,
        download: true,
        display: sastScan && sastScan.application.pciDSSEnabled ? true : false,
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value !== '---') {
            return (
              <div style={{
                display: "flex",
                alignItems: "center",
                flexDirection: 'column',
              }}>
                {value.map(val => (
                  <text style={{
                    padding: 5,
                    margin: 5,
                    backgroundColor: '#fffde7',
                    color: '#000',
                    fontWeight: 'bold',
                    width: 120,
                    textAlign: 'center',
                    borderRadius: 10,
                    fontSize: 13
                  }}>{val}</text>
                ))}
              </div>
            )
          } else {
            return (
              <div style={{
                display: "flex",
                alignItems: "center"
              }}>
                ---
              </div>
            )
          }
        }
      }
    },
    {
      label: "References",
      options: {
        filter: false,
        download: true,
      }
    },
    {
      label: "Remediations",
      options: {
        filter: false,
        download: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{
              display: "flex",
              alignItems: "center"
            }}>
              <CButton color="primary" variant="outline"
                onClick={() => openRemediationModal(value)}
                className="m-1" style={{ fontSize: 13, borderColor: 'white', color: 'white' }}>
                Remediations/Fix
              </CButton>
            </div>
          )
        }
      }
    },
  ]

  const getMuiTheme = () => createTheme({
    components: {
      MUIDataTableBodyCell: {
        styleOverrides: {
          root: {
            textAlign: "left",
            '&:nth-child(1)': {
              width: 30,
            },
          }
        }
      },
      MUIDataTableHeadCell: {
        styleOverrides: {
          root: {
            textAlign: "left",
            '&:nth-child(1)': {
              width: 30
            },
          }
        }
      },
    }
  })

  const options = {
    filterType: "multiselect",
    responsive: "stacked",
    serverSide: true,
    count: count,
    page: page,
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: [10, 20, 60, 100, 150],
    customSearchRender: (searchText, handleSearch, hideSearch, options) => {
      return (
        <div style={{ 
          display: 'flex', 
          alignItems: 'center',
          gap: '10px',
          padding: '8px 16px'
        }}>
          <input
            type="text"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                setSearchText(searchInput);
                setPage(0);
                loadScanDetails(
                  scanId,
                  0,
                  rowsPerPage,
                  searchInput,
                  filterList
                );
              }
            }}
            placeholder="Search vulnerabilities..."
            style={{
              padding: '8px 12px',
              borderRadius: '4px',
              border: '1px solid #ccc',
              width: '300px',
              fontSize: '14px',
              color: '#000',
            }}
          />
          <button
            onClick={() => {
              setSearchText(searchInput);
              setPage(0);
              loadScanDetails(
                scanId,
                0,
                rowsPerPage,
                searchInput,
                filterList
              );
            }}
            style={{
              padding: '8px 16px',
              borderRadius: '4px',
              border: 'none',
              backgroundColor: '#1976d2',
              color: 'white',
              cursor: 'pointer',
              fontSize: '14px'
            }}
          >
            Search
          </button>
          {searchInput && (
            <button
              onClick={() => {
                setSearchInput('');
                setSearchText('');
                setPage(0);
                loadScanDetails(
                  scanId,
                  0,
                  rowsPerPage,
                  '',
                  filterList
                );
              }}
              style={{
                padding: '8px 16px',
                borderRadius: '4px',
                border: 'none',
                backgroundColor: '#d32f2f',
                color: 'white',
                cursor: 'pointer',
                fontSize: '14px'
              }}
            >
              Clear
            </button>
          )}
        </div>
      );
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          setPage(tableState.page);
          loadScanDetails(
            scanId,
            tableState.page,
            tableState.rowsPerPage,
            searchText,
            filterList
          );
          break;

        case 'changeRowsPerPage':
          setRowsPerPage(tableState.rowsPerPage);
          setPage(0);
          loadScanDetails(
            scanId,
            0,
            tableState.rowsPerPage,
            searchText,
            filterList
          );
          break;

        case 'filterChange':
          const newFilterList = {};
          tableState.filterList.forEach((filter, index) => {
            if (filter && filter.length) {
              newFilterList[columns[index].label] = filter;
            }
          });
          setFilterList(newFilterList);
          setPage(0);
          loadScanDetails(
            scanId,
            0,
            tableState.rowsPerPage,
            searchText,
            newFilterList
          );
          break;

        case 'resetFilters':
          setFilterList({});
          setPage(0);
          loadScanDetails(
            scanId,
            0,
            tableState.rowsPerPage,
            searchText,
            {}
          );
          break;
      }
    },
    textLabels: {
      body: {
        noMatch: onLoading ? 'Loading data...' : 'No vulnerabilities found',
      }
    },
    filter: true,
    search: true,
    searchOpen: true,
    selectableRows: 'none',
    download: true,
    print: true,
    viewColumns: true,
    elevation: 0,
  }

  let tableData = [];

  if (sastScan && sastScan.vulnerabilities) {
    tableData = sastScan.vulnerabilities.map((vuln, index) => {
      const severity = vuln.category === 'security'
        ? (vuln.severity === 'WARNING' && !vuln.impact ? 'LOW' : vuln.impact)
        : 'INFORMATIONAL';

      return [
        ((page) * rowsPerPage) + (index + 1),
        vuln.title,
        vuln.message,
        vuln.filePath,
        `Col:${vuln.startCol} Line:${vuln.startLine} Offset:${vuln.startOffset}`,
        `Col:${vuln.endCol} Line:${vuln.endLine} Offset:${vuln.endOffset}`,
        severity,
        vuln.category === 'security' ? (vuln.owasp?.length > 0 ? vuln.owasp : '---') : '---',
        vuln.category === 'security' ? (vuln.cwe?.length > 0 ? vuln.cwe : '---') : '---',
        vuln.category === 'security' ? (vuln.pciViolations?.length > 0 ? vuln.pciViolations : '---') : '---',
        vuln.references?.length > 0 ? vuln.references.join('\n\n') : '---',
        vuln
      ];
    });
  }

  const labelsArray = ['CRITICAL', 'HIGH', 'MEDIUM', 'LOW', 'INFORMATIONAL'];
  const bgColorArray = ['#990000', '#FF6600', '#FFCC00', '#669933', '#0094D4'];

  const vulnDistrochartOptions = {
    labels: labelsArray,
    colors: bgColorArray,
    legend: {
      position: 'bottom',
      verticalAlign: 'middle',
      onItemHover: {
        highlightDataSeries: false
      }
    }
  };

  const vulnDistroChartSeries = severityCounts;

  return (
    <div style={{ overflow: "scroll", position: 'relative', overflowY: 'hidden', overflowX: 'hidden' }}>
      <>
        {onLoading ? (
          <div style={{
            width: '80%', marginLeft: '10%', marginRight: '10%', marginTop: '2%'
          }}>
            <ShimmerTable row={5} col={10} />
          </div>
        ) : (
          <div style={{
            width: '100%', marginLeft: '0%', marginRight: '0%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between',
            marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
          }}>
            <div style={{ marginBottom: '2rem' }}>
              <h2>{sastScan.projectName}</h2>
              <hr />

              <table style={{ width: 600 }}>
                <tbody>
                  <tr>
                    <td style={{ padding: 10, borderWidth: 1, borderColor: '#fff', background: '#fff' }}>
                      <h6 style={{ fontWeight: 'bold', color: '#000' }}>Scan ID</h6>
                    </td>
                    <td style={{ padding: 10, borderWidth: 1, borderColor: '#fff' }}>
                      {scanId}
                    </td>
                  </tr>

                  <tr>
                    <td style={{ padding: 10, borderWidth: 1, borderColor: '#fff', background: '#fff' }}>
                      <h6 style={{ fontWeight: 'bold', color: '#000' }}>Scan Name</h6>
                    </td>
                    <td style={{ padding: 10, borderWidth: 1, borderColor: '#fff' }}>
                      {sastScan.scanName}
                    </td>
                  </tr>

                  <tr>
                    <td style={{ padding: 10, borderWidth: 1, borderColor: '#fff', background: '#fff' }}>
                      <h6 style={{ fontWeight: 'bold', color: '#000' }}>Application Name</h6>
                    </td>
                    <td style={{ padding: 10, borderWidth: 1, borderColor: '#fff' }}>
                      {sastScan.application.name}
                    </td>
                  </tr>

                  <tr>
                    <td style={{ padding: 10, borderWidth: 1, borderColor: '#fff', background: '#fff' }}>
                      <h6 style={{ fontWeight: 'bold', color: '#000' }}>Files Scanned</h6>
                    </td>
                    <td style={{ padding: 10, borderWidth: 1, borderColor: '#fff' }}>
                      {sastScan.filesScanned.length}
                    </td>
                  </tr>

                  <tr>
                    <td style={{ padding: 10, borderWidth: 1, borderColor: '#fff', background: '#fff' }}>
                      <h6 style={{ fontWeight: 'bold', color: '#000' }}>Vulnerabilities</h6>
                    </td>
                    <td style={{ padding: 10, borderWidth: 1, borderColor: '#fff' }}>
                      {count}
                    </td>
                  </tr>

                  <tr>
                    <td style={{ padding: 10, borderWidth: 1, borderColor: '#fff', background: '#fff' }}>
                      <h6 style={{ fontWeight: 'bold', color: '#000' }}>Scan Started At</h6>
                    </td>
                    <td style={{ padding: 10, borderWidth: 0.5, borderColor: '#fff' }}>
                      {(new Date(sastScan.createdAt)).toLocaleDateString('en-US')} - {(new Date(sastScan.createdAt)).toLocaleTimeString('en-US')}
                    </td>
                  </tr>

                  <tr>
                    <td style={{ padding: 10, borderWidth: 1, borderColor: '#fff', background: '#fff' }}>
                      <h6 style={{ fontWeight: 'bold', color: '#000' }}>Scan Status</h6>
                    </td>
                    <td style={{ padding: 10, borderWidth: 0.5, borderColor: '#fff' }}>
                      {sastScan.status ? sastScan.status.toUpperCase() : '---'}
                    </td>
                  </tr>

                  {sastScan.scanCompletedAt && (
                    <tr>
                      <td style={{ padding: 10, borderWidth: 1, borderColor: '#fff', background: '#fff' }}>
                        <h6 style={{ fontWeight: 'bold', color: '#000' }}>Scan Completed At</h6>
                      </td>
                      <td style={{ padding: 10, borderWidth: 1, borderColor: '#fff' }}>
                        {(new Date(sastScan.scanCompletedAt)).toLocaleDateString('en-US')} - {(new Date(sastScan.scanCompletedAt)).toLocaleTimeString('en-US')}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <CButton
                onClick={goBack}
                style={{
                  width: 200,
                  marginBottom: '2%',
                  borderWidth: 1,
                  fontSize: 13,
                  borderColor: '#ffffff',
                  color: '#ffffff',
                  background: 'transparent'
                }}
                color="primary"
                className="px-3"
              >
                <IoMdArrowRoundBack size={25} style={{ color: '#fff', marginRight: 10 }} /> Back to Scans List
              </CButton>

              <div style={{ display: 'flex' }}>
                {sastScan.status === 'Completed' && count>0 && (
                  <CButton
                    onClick={handleClick}
                    style={{
                      width: 200,
                      marginBottom: '2%',
                      borderWidth: 0,
                      fontSize: 13,
                      marginTop: 10,
                      background: '#e50202'
                    }}
                    color="primary"
                    className

                    ="px-3"
                  >
                    {exportingPDF ? (
                      <CircularProgress color="primary" size={24} style={{ marginTop: 10, color: '#fff' }} />
                    ) : (
                      <>
                        Download Report <FaCaretDown size={25} style={{ color: '#ffffff', marginLeft: 10 }} />
                      </>
                    )}
                  </CButton>
                )}

                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  style={{ width: '100%', color: 'white' }}
                >
                  <MenuItem onClick={() => handleMenuItemClick('summary')} style={{ width: '100%', color: 'white' }}>Summary Report (PDF)</MenuItem>
                  <MenuItem onClick={() => handleMenuItemClick('detailed')} style={{ width: '100%', color: 'white' }}>Detailed Report (PDF)</MenuItem>
                  <MenuItem onClick={() => handleMenuItemClick('custom')} style={{ width: '100%', color: 'white' }}>Custom Report (PDF)</MenuItem>
                  <MenuItem onClick={() => handleMenuItemClick('xml')} style={{ width: '100%', color: 'white' }}>Export to XML</MenuItem>
                  <MenuItem onClick={() => handleMenuItemClick('json')} style={{ width: '100%', color: 'white' }}>Export to JSON</MenuItem>
                </Menu>
              </div>
            </div>
          </div>
        )}

        <div style={{
          width: '100%', marginLeft: '0%', marginRight: '0%', display: 'flex', flexDirection: 'column',
          marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
        }}>
          <h4 style={{ marginTop: 30 }}>Severity Scoring</h4>
          <hr />

          <table style={{ 
   borderCollapse: 'separate',
   borderSpacing: '0', 
   width: '100%',
   maxWidth: '1200px',
   margin: '20px auto',
   border: '1px solid #e0e0e0',
   borderRadius: '8px',
   boxShadow: '0 2px 4px rgba(0,0,0,0.05)'
}}>
   <tbody>
       <tr>
           <td style={{ 
               width: '150px', 
               padding: '15px',
               verticalAlign: 'middle',
               borderBottom: '1px solid #e0e0e0',
               textAlign: 'center'
           }}>
               <span style={{ 
                   fontWeight: 'bold', 
                   backgroundColor: '#990000', 
                   color: '#fff', 
                   padding: '5px 15px', 
                   borderRadius: '5px', 
                   display: 'inline-block',
                   width: '100px',
                   textAlign: 'center'
               }}>
                   CRITICAL
               </span>
           </td>
           <td style={{ 
               padding: '15px 20px',
               verticalAlign: 'middle',
               borderBottom: '1px solid #e0e0e0',
               borderLeft: '1px solid #e0e0e0'
           }}>
               Vulnerabilities that can be exploited remotely, leading to immediate and widespread impact on the confidentiality, integrity, and availability of systems or data.
           </td>
       </tr>
       
       <tr>
           <td style={{ 
               width: '150px', 
               padding: '15px',
               verticalAlign: 'middle',
               borderBottom: '1px solid #e0e0e0',
               textAlign: 'center'
           }}>
               <span style={{ 
                   fontWeight: 'bold', 
                   backgroundColor: '#FF6600', 
                   color: '#fff', 
                   padding: '5px 15px', 
                   borderRadius: '5px', 
                   display: 'inline-block',
                   width: '100px',
                   textAlign: 'center'
               }}>
                   HIGH
               </span>
           </td>
           <td style={{ 
               padding: '15px 20px',
               verticalAlign: 'middle',
               borderBottom: '1px solid #e0e0e0',
               borderLeft: '1px solid #e0e0e0'
           }}>
               Vulnerabilities that can be exploited but require some form of user interaction or other preconditions to be met, potentially resulting in significant impact on system or data.
           </td>
       </tr>
       
       <tr>
           <td style={{ 
               width: '150px', 
               padding: '15px',
               verticalAlign: 'middle',
               borderBottom: '1px solid #e0e0e0',
               textAlign: 'center'
           }}>
               <span style={{ 
                   fontWeight: 'bold', 
                   backgroundColor: '#FFCC00', 
                   color: '#000', 
                   padding: '5px 15px', 
                   borderRadius: '5px', 
                   display: 'inline-block',
                   width: '100px',
                   textAlign: 'center'
               }}>
                   MEDIUM
               </span>
           </td>
           <td style={{ 
               padding: '15px 20px',
               verticalAlign: 'middle',
               borderBottom: '1px solid #e0e0e0',
               borderLeft: '1px solid #e0e0e0'
           }}>
               Vulnerabilities that could result in a compromise of system or data security, but require more complex exploitation techniques or have limited impact.
           </td>
       </tr>
       
       <tr>
           <td style={{ 
               width: '150px', 
               padding: '15px',
               verticalAlign: 'middle',
               textAlign: 'center'
           }}>
               <span style={{ 
                   fontWeight: 'bold', 
                   backgroundColor: '#669933', 
                   color: '#fff', 
                   padding: '5px 15px', 
                   borderRadius: '5px', 
                   display: 'inline-block',
                   width: '100px',
                   textAlign: 'center'
               }}>
                   LOW
               </span>
           </td>
           <td style={{ 
               padding: '15px 20px',
               verticalAlign: 'middle',
               borderLeft: '1px solid #e0e0e0'
           }}>
               Vulnerabilities that have a low likelihood of being exploited or have minimal impact on system or data security.
           </td>
       </tr>
   </tbody>
</table>


        </div>

        {onLoading ? (
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 50 }}>
            <ShimmerTable row={8} col={10} />
          </div>
        ) : (
          <>
            {sastScan.vulnerabilities.length > 0 && (
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: 50, height: 500 }}>
                <div style={{
                  width: '30%', marginLeft: '10%', marginRight: '10%', height:'60vh',
                   display: 'flex', flexDirection: 'column',
                  marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)',
                   padding: 20, borderRadius: 15
                }}>
                  <h4 style={{ color: '#fff', textAlign: 'center' }}>Severity Distribution</h4>
                  <hr />
                  <Chart options={vulnDistrochartOptions} series={vulnDistroChartSeries} 
                  type="pie" />
                </div>
              </div>
            )}
          </>
        )}

        <div style={{
          width: '100%', marginLeft: '0%', marginRight: '0%', display: 'flex', flexDirection: 'column',
          marginTop: '2%'
        }}>
          {onLoading ? (
            <ShimmerTable row={8} col={10} />
          ) : (
            <>
              {tableData.length > 0 ? (
                <ThemeProvider theme={getMuiTheme()}>
                  <MUIDataTable
                    style={{ height: "57vh" }}
                    data={tableData}
                    columns={columns}
                    options={options}
                  />
                </ThemeProvider>
              )
            :
            <h4 style={{textAlign:'center', marginBottom:30, color:'#fff'}}>No vulnerabilities Found</h4>
            }
            </>
          )}
        </div>

        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Remediations"
        >
          <button style={{ float: 'right', backgroundColor: 'transparent', borderWidth: 0 }} onClick={closeModal}>
            <AiFillCloseCircle size={30} color={'#000'} />
          </button>

          {currentVulnerability && (
            <div className="modalWindow" style={{ backgroundColor: '#E1E1E1' }}>
              <h5 style={{ color: '#000' }}>
                <strong>Issue</strong>: {currentVulnerability.title}
              </h5>
              <p style={{ color: '#000' }}>
                <strong>Description</strong>: {currentVulnerability.message}
              </p>

              <span
                style={{
                  backgroundColor: (() => {
                    const severity = currentVulnerability.category === 'security'
                      ? (currentVulnerability.severity === 'WARNING' && !currentVulnerability.impact ? 'LOW' : currentVulnerability.impact)
                      : 'INFORMATIONAL';
                    
                    const colors = {
                      'CRITICAL': '#990000',
                      'HIGH': '#FF6600',
                      'MEDIUM': '#FFCC00',
                      'LOW': '#669933',
                      'INFORMATIONAL': '#0094D4'
                    };
                    return colors[severity] || '#e0e0e0';
                  })(),
                  color: (() => {
                    const severity = currentVulnerability.category === 'security'
                      ? (currentVulnerability.severity === 'WARNING' && !currentVulnerability.impact ? 'LOW' : currentVulnerability.impact)
                      : 'INFORMATIONAL';
                    
                    const colors = {
                      'CRITICAL': '#fff',
                      'HIGH': '#fff',
                      'MEDIUM': 'black',
                      'LOW': '#000',
                      'INFORMATIONAL': '#000'
                    };
                    return colors[severity] || '#000';
                  })(),
                  padding: '10px 20px',
                  borderRadius: '8px',
                  display: 'inline-block',
                  fontWeight: 'bold',
                  textAlign: 'center',
                  marginBottom: '20px'
                }}
              >
                {currentVulnerability.category === 'security'
                  ? (currentVulnerability.severity === 'WARNING' && !currentVulnerability.impact ? 'LOW' : currentVulnerability.impact)
                  : 'INFORMATIONAL'}
              </span>

              <hr />

              <h5 style={{ color: '#000' }}>Remediation</h5>
              <div style={{ backgroundColor: '#ffffff', padding: 15, marginTop: 10, 
                borderRadius: 8 }}>
                <div
                  className="remedCard"
                  style={{ color: '#000', fontSize: 14 }}
                  dangerouslySetInnerHTML={{ __html: currentVulnerability.remediation }}
                />
              </div>

              <hr />

              <h5 style={{ color: '#000' }}>Suggested Fix</h5>
              <div style={{ backgroundColor: '#ffffff', padding: 15, marginTop: 10, borderRadius: 8 }}>
                <div
                  className="remedCard"
                  style={{ color: '#000', fontSize: 14 }}
                  dangerouslySetInnerHTML={{ __html: currentVulnerability.suggestedFix }}
                />
              </div>

              {currentVulnerability.references && currentVulnerability.references.length > 0 && (
                <>
                  <hr />
                  <h5 style={{ color: '#000' }}>References</h5>
                  <div style={{ backgroundColor: '#ffffff', padding: 15, marginTop: 10, borderRadius: 8 }}>
                    {currentVulnerability.references.map((reference, index) => (
                      <div key={index} style={{ color: '#000', fontSize: 14, marginBottom: 5 }}>
                        {reference}
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
          )}
        </Modal>

        <CustomReportModal
          open={customReportModalOpen}
          onClose={() => setCustomReportModalOpen(false)}
          onGenerateReport={handleGenerateCustomReport}
        />
      </>
    </div>
  );
};

export default ScanDetails;