import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import lightLogo from '../../../assets/images/intrucept-light-logo.svg'
import { useDispatch } from 'react-redux'
import showPassword from '../../../assets/images/show-password.png'
import hidePassword from '../../../assets/images/hide-password.png'
import { CircularProgress } from '@mui/material';
import { validateEmail } from '../../../helpers/validation';

import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CRow,
} from '@coreui/react'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import axios from 'axios'



const Login = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  let { role } = useParams()

  useEffect(() => {
    const user = localStorage.getItem('ASIUser');
    if (user) {
      navigate('/dashboard');
    }
  }, [navigate]);

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [submitting, setSubmitting] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');
  const [isError, setIsError] = useState(false);

  const [showingPassword, setShowingPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowingPassword(!showingPassword)
  }


  const handleEmailChange = (e) => {

    const value = e.target.value;

    if (value.length <= 50) {
      setEmail(value);
    }
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    if (value.length <= 50) {
      setPassword(value);
    }
  };

  

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      login();
    }
  };

  const login = async () => {

    if (email.length === 0 || password.length === 0) {

      setIsError(true);
      setErrorMessage('Please enter email and password');
    } else if (!validateEmail(email)) {

      setIsError(true);
      setErrorMessage('Invalid email');
    } else {

      setSubmitting(true);
      const url = '/api/v1/users/login';
      const data = { email, password };

      try {
        const response = await axios.post(url, data);

        if (response.status === 200 && response.data.data.user) {
          const user = response.data.data.user;
          localStorage.setItem('ASIUser', JSON.stringify(user));
          localStorage.setItem('ASIToken', response.data.data.token);
          setSubmitting(false);

          if (user.userType === 'user') {
            navigate('/dashboard');
          } else if (user.userType === 'superadmin') {
            navigate('/organizations');
          }
        } else {
          setIsError(true);
          setErrorMessage('Incorrect email or password');
        }
      } catch (error) {
        setSubmitting(false);
        if (error.response && error.response.status === 401) {
          setIsError(true);
          setErrorMessage('Incorrect email or password');
        } else {
          setIsError(true);
          setErrorMessage('Something went wrong. Please try again later');
        }
      }
    }
  };

  return (
    <div className="min-vh-100 d-flex flex-row align-items-center" style={{ backgroundColor: '#1d222e' }}>
      <CContainer style={{ backgroundColor: '#1d222e' }}>
        <CRow className="justify-content-center">
          <CCol md={5}>
            <CCardGroup>
              <CCard className="p-4 border border-end-0" style={{ backgroundColor: '#fefeff' }}>
                <CCardBody>
                  <CForm style={{ display: 'flex', flexDirection: 'column', width: '70%', marginLeft: '15%', justifyContent: 'center' }}>
                    <img src={lightLogo} style={{ width: '80%', alignSelf: 'center' }} alt="" />
                    <text className="login-page-card-heading" style={{ marginTop: '1rem', textAlign: 'center', color: '#343434', fontSize: 20, fontWeight: 'bold' }}>
                      AppSecOps Portal
                    </text>
                    <text className="login-page-card-heading" style={{ marginTop: '1rem', textAlign: 'center', color: '#000', fontSize: 17, fontWeight: 'bold' }}>
                      Login to Your Account
                    </text>
                    <CInputGroup className="mb-3 mt-3" style={{ flexDirection: 'column', marginTop: 30 }}>
                      <CFormInput
                        placeholder="Email"
                        value={email}
                      //  onChange={(e) => setEmail(e.target.value)}
                        onChange={handleEmailChange}
                        onKeyUp={handleKeyPress}
                        autoComplete="username"
                        className="blackText"
                        style={{ width: '100%' }}
                      />
                    </CInputGroup>
                    <CInputGroup className="mb-4" style={{ flexDirection: 'column' }}>
                      <CFormInput
                        type={showingPassword ? 'text' : 'password'}
                        placeholder="Password"
                        value={password}
                        autoComplete="current-password"
                        onChange={handlePasswordChange}
                        // onChange={(e) => setPassword(e.target.value)}
                        onKeyUp={handleKeyPress}
                        className="blackText"
                        style={{ width: '100%' }}
                      />
                      <img
                        src={!showingPassword ? hidePassword : showPassword}
                        style={{ width: 20, height: 20, position: 'absolute', right: 10, top: 8, zIndex: 1000 }}
                        onClick={toggleShowPassword}
                      />
                      {isError && <span style={{ color: 'red', fontSize: 12, marginTop: 5 }}>{errorMessage}</span>}
                    </CInputGroup>
                    <CRow>
                      <CCol xs={60}>
                        <CButton
                          style={{
                            width: '100%', marginBottom: '2%', borderWidth: 0, fontSize: 20,
                            background: 'linear-gradient(135deg, #161c2a, #1a2130)'
                          }}
                          color="primary"
                          className="px-3"
                          onClick={login}
                        >
                          {submitting ? (
                            <CircularProgress color="primary" size={24} style={{ marginTop: 10, color: '#fff' }} />
                          ) : (
                            'Login'
                          )}
                        </CButton>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default Login;
