import React, { useState, useEffect, useRef } from "react";
import { CButton } from '@coreui/react'
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom'
import axios from 'axios';
import { ShimmerTable } from "react-shimmer-effects";
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AiOutlineAppstoreAdd } from "react-icons/ai";
import { MdDeleteOutline } from "react-icons/md";
import { CiEdit } from "react-icons/ci";

const Users = () => {
  const navigate = useNavigate();
  
  // State Management
  const [users, setUsers] = useState([]);
  const [onLoading, setOnLoading] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  // Pagination, Search and Filter states
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchText, setSearchText] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const [filterList, setFilterList] = useState({});
  const [filterOptions, setFilterOptions] = useState({
    roles: [],
    statuses: [],
    locations: [],
    businessUnits: []
  });

  const prevSearchInput = useRef(searchInput);

  const customStyles = {
    content: {
      top: '30%',
      left: '25%',
      width: '50%',
      right: 'auto',
      bottom: 'auto',
      height: '25%',
      backgroundColor: '#ffffff',
      borderRadius: 15,
      borderColor: 'ffffff'
    },
  };

  const fetchUsers = async (isFirstTime, page, rowsPerPage, searchText, filterList) => {
    if (isFirstTime) {
      setOnLoading(true);
    }
  
    const token = localStorage.getItem('ASIToken');
    try {
      const queryParams = new URLSearchParams();
      
      if (searchText) {
        queryParams.append('search', searchText);
      }
      
      if (Object.keys(filterList).length > 0) {
        queryParams.append('filterList', JSON.stringify(filterList));
      }

      const response = await axios.get(
        `/api/v1/organizations/users/${page}/${rowsPerPage}${queryParams.toString() ? `?${queryParams.toString()}` : ''}`,
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
  
      setUsers(response.data.users);
      setCount(response.data.totalCount);
      setFilterOptions(response.data.filterOptions);
    } catch (error) {
      console.error('Error fetching users:', error);
      toast.error('Error loading users');
    } finally {
      setOnLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers(true, 0, rowsPerPage, '', {});
  }, []);

  useEffect(() => {
    if (prevSearchInput.current && searchInput.length === 0) {
      fetchUsers(true, 0, rowsPerPage, '', filterList);
    }
    prevSearchInput.current = searchInput;
  }, [searchInput]);

  const handleClick = (user) => {
    setUserToDelete(user);
    setModalIsOpen(true);
  };

  const handleConfirmation = (confirmed) => {
    if (confirmed) {
      deleteUser(userToDelete);
    }
    setModalIsOpen(false);
  };

  const deleteUser = async (id) => {
    const token = localStorage.getItem('ASIToken');
    try {
      await axios.delete(`/api/v1/organizations/users`, {
        headers: { Authorization: `Bearer ${token}` },
        data: { id }
      });

      toast.success('User deleted');
      fetchUsers(true, page, rowsPerPage, searchText, filterList);
    } catch (error) {
      console.error('Error:', error);
      toast.error('Error deleting user');
    }
  };

  const goToEditUser = (id) => {
    navigate('/edit-user?userId=' + id);
  };

  const goToAddUser = () => {
    navigate('/add-user');
  };

  const columns = [
    {
      name: "index",
      label: "#",
      options: {
        filter: false,
        download: true,
        customBodyRender: (value, tableMeta) => {
          return ((page * rowsPerPage) + tableMeta.rowIndex + 1);
        }
      }
    },
    {
      name: "role",
      label: "Role",
      options: {
        filter: true,
        filterOptions: {
          names: filterOptions.roles,
          logic: (value, filters) => {
            if (filters.length === 0) return false;
            return !filters.includes(value);
          }
        },
        filterList: filterList['Role'] || []
      }
    },
    {
      name: "firstName",
      label: "First Name",
      options: {
        filter: false,
        download: true,
      }
    },
    {
      name: "lastName",
      label: "Last Name",
      options: {
        filter: false,
        download: true,
      }
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: false,
        download: true,
      }
    },
    {
      name: "phoneNumber",
      label: "Phone Number",
      options: {
        filter: false,
        download: true,
      }
    },
    {
      name: "businessUnit",
      label: "Business Unit",
      options: {
        filter: true,
        filterOptions: {
          names: filterOptions.businessUnits,
          logic: (value, filters) => {
            if (filters.length === 0) return false;
            return !filters.includes(value);
          }
        },
        filterList: filterList['Business Unit'] || [],
        customBodyRender: (value) => value?.name || '---'
      }
    },
    {
      name: "location",
      label: "Location",
      options: {
        filter: true,
        filterOptions: {
          names: filterOptions.locations,
          logic: (value, filters) => {
            if (filters.length === 0) return false;
            return !filters.includes(value);
          }
        },
        filterList: filterList['Location'] || []
      }
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: true,
        filterOptions: {
          names: filterOptions.statuses,
          logic: (value, filters) => {
            if (filters.length === 0) return false;
            return !filters.includes(value);
          }
        },
        filterList: filterList['Status'] || []
      }
    },
    {
      name: "_id",
      label: "Actions",
      options: {
        filter: false,
        download: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const currentUser = JSON.parse(localStorage.getItem('ASIUser'));
          
          return (
            <div style={{
              display: "flex",
              alignItems: "center"
            }}>
              <CButton color="info"
                onClick={() => goToEditUser(value)}
                variant="outline"
                className="m-1"
                style={{ width: '100%', fontSize: 12, fontWeight: 'bold', color:'blue', borderColor:'blue' }}>
                <CiEdit size={15} style={{ color: 'blue' }} />
              </CButton>

              {currentUser._id !== value &&
                <CButton color="danger"
                  onClick={() => handleClick(value)}
                  variant="outline"
                  className="m-1"
                  style={{ width: '100%', fontSize: 12, fontWeight: 'bold', color:'red', borderColor:'red' }}>
                  <MdDeleteOutline size={15} style={{ color: 'red' }} />
                </CButton>
              }
            </div>
          )
        }
      }
    },
  ];

  const getMuiTheme = () => createTheme({
    components: {
      MUIDataTableBodyCell: {
        styleOverrides: {
          root: {
            textAlign: "left",
            '&:nth-child(1)': {
              width: 30,
            },
          }
        }
      },
      MUIDataTableHeadCell: {
        styleOverrides: {
          root: {
            textAlign: "left",
            '&:nth-child(1)': {
              width: 30,
            },
          }
        }
      },
    }
  });

  const options = {
    filterType: "multiselect",
    responsive: "stacked",
    elevation: 0,
    filter: true,
    download: true,
    print: true,
    search: true,
    searchOpen: true,
    viewColumns: true,
    selectableRows: false,
    serverSide: true,
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: [10, 20, 60, 100, 150],
    count: count,
    page: page,
    customSearchRender: (searchText, handleSearch, hideSearch, options) => {
      return (
        <div style={{ 
          display: 'flex', 
          alignItems: 'center',
          gap: '10px',
          padding: '8px 16px'
        }}>
          <input
            type="text"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                setSearchText(searchInput);
                setPage(0);
                fetchUsers(true, 0, rowsPerPage, searchInput, filterList);
              }
            }}
            placeholder="Search users..."
            style={{
              padding: '8px 12px',
              borderRadius: '4px',
              border: '1px solid #ccc',
              width: '300px',
              fontSize: '14px',
              color: '#000',
            }}
          />
          <button
            onClick={() => {
              setSearchText(searchInput);
              setPage(0);
              fetchUsers(true, 0, rowsPerPage, searchInput, filterList);
            }}
            style={{
              padding: '8px 16px',
              borderRadius: '4px',
              border: 'none',
              backgroundColor: '#1976d2',
              color: 'white',
              cursor: 'pointer',
              fontSize: '14px'
            }}
          >
            Search
          </button>
          {searchInput && (
            <button
              onClick={() => {
                setSearchInput('');
                setSearchText('');
                setPage(0);
                fetchUsers(true, 0, rowsPerPage, '', filterList);
              }}
              style={{
                padding: '8px 16px',
                borderRadius: '4px',
                border: 'none',
                backgroundColor: '#d32f2f',
                color: 'white',
                cursor: 'pointer',
                fontSize: '14px'
              }}
            >
              Clear
            </button>
          )}
        </div>
      );
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          setPage(tableState.page);
          fetchUsers(true, tableState.page, tableState.rowsPerPage, searchText, filterList);
          break;

        case 'changeRowsPerPage':
          setRowsPerPage(tableState.rowsPerPage);
          setPage(0);
          fetchUsers(true, 0, tableState.rowsPerPage, searchText, filterList);
          break;

        case 'filterChange':
          const newFilterList = {};
          tableState.filterList.forEach((filter, index) => {
            if (filter && filter.length) {
              newFilterList[columns[index].label] = filter;
            }
          });
          setFilterList(newFilterList);
          setPage(0);
          fetchUsers(true, 0, tableState.rowsPerPage, searchText, newFilterList);
          break;

        case 'resetFilters':
          setFilterList({});
          setPage(0);
          fetchUsers(true, 0, tableState.rowsPerPage, searchText, {});
          break;
      }
    },
    textLabels: {
      body: {
        noMatch: onLoading ? 'Loading users...' : 'No users found',
      }
    }
  };

  const tableData = users;

  return (
    <div className="activeScans">
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        style={customStyles}
        contentLabel="Confirm Delete"
      >
        <text style={{ color: '#000', fontSize: 18 }}>Are you sure you want to permanently delete this user?</text>
        <br/><br/>
        <button onClick={() => handleConfirmation(true)} 
          style={{ width: 100, borderWidth: 0, borderRadius:5, backgroundColor: 'green', color:'white', padding: 10 }}>
          Yes
        </button>
        <button onClick={() => handleConfirmation(false)} 
          style={{ marginLeft: 30, borderWidth: 0, borderRadius:5, width: 100, backgroundColor: 'red', color:'white', padding: 10 }}>
          No
        </button>
      </Modal>

      <div style={{ width: '100%' }}>
        <div>
          <div style={{ marginBottom: '2rem', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <h4><strong>Users</strong></h4>
            <CButton
              style={{
                width: 200,
                marginBottom: '2%',
                borderWidth: 0,
                fontSize: 13,
                fontStyle:'bold',
                background: '#e50202'
              }}
              onClick={goToAddUser}
              color="primary"
              className="px-3"
            >
              <AiOutlineAppstoreAdd size={24} style={{ color: 'white' }} />
              <span style={{marginLeft:15}}>Add a User</span>
            </CButton>
          </div>

          {onLoading ? (
            <ShimmerTable row={8} col={10} />
          ) : (
            <ThemeProvider theme={getMuiTheme()}>
              <MUIDataTable
                style={{ height: "57vh" }}
                data={tableData}
                columns={columns}
                options={options}
              />
            </ThemeProvider>
          )}
        </div>
      </div>
    </div>
  );
};

export default Users;