import React, { useState, useEffect, useRef } from "react";

import axios from 'axios';

import '../style.css';
import noData from '../../../assets/nodata.png'

const Top10UniqueApplicationFindings = () => {


  const [result, setResult] = useState([])


  useEffect(() => {

    getDashboardData();

  }, []);

  const getDashboardData = async () => {
    
    try {
      const token = localStorage.getItem('ASIToken');
      const response = await axios.get('/api/v1/users/organization-level-top-10-unique-application-findings', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setResult(response.data);
    } catch (error) {
      console.error("Error fetching top 10 unique application findings:", error);
    }
  };


  return (
    <>

      <div className="dashboardCard" style={{
        display: 'flex', flexDirection: 'column', backgroundColor: '#fff',
        borderRadius: 10, padding: 20, width: '47%'
      }}>


        <span style={{ fontSize: 15 }}>TOP 10 UNIQUE APPLICATION FINDINGS</span>
        <hr style={{ borderColor: '#fff' }} />


       {result && Array.isArray(result) ? 

        <table style={{ width: '100%' }}>

          <thead>

            <th style={{ padding: 15 }}>Finding</th>
            <th style={{ padding: 15 }}>Count</th>


          </thead>

          <tbody>


            {result && Array.isArray(result) && result.map((item, index) => (
              <tr>
                <td style={{ textAlign: 'left', fontSize: 13, fontWeight: 'normal' }}>
                  {item.finding}
                </td>
                <td style={{ textAlign: 'left', fontSize: 13, fontWeight: 'normal' }}>
                  {item.count}
                </td>
              </tr>

            ))}


          </tbody>

        </table>

        :

        <div style={{display:'flex', flexDirection:'column', 
                    justifyContent:'center', alignItems:'center'}}>
                <hr />
                <img src={noData} width={30} style={{marginTop: '10%'}}/>
                <span style={{ textAlign: 'center', fontSize:11 }}>No data available</span>
                
        </div>
}

      </div>
    </>
  )
}

export default Top10UniqueApplicationFindings